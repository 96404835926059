<template>
  <div class="no-auth">
    <div class="bg"></div>
    <div class="text">
      <div>未模范履行业主义务的居民，暂无订餐权限。请到物业中心缴纳物业费，履行业主义务，如有疑问请联系社区工作人员</div>
      <a href="tel:0574-55711314" style="color: #007eff">0574-55711314</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eldCannNoAuth',
}
</script>

<style lang="less" scoped>
.no-auth {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-top: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .bg {
    box-sizing: border-box;
    width: 358px;
    height: 302px;
    background-image: url('./img/no_auth_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .text {
    font-size: 26px;
    line-height: 42px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 20px;
    padding: 0px 30px;
    text-align: center;
  }
}
</style>
